<template>
  <div class="article-container">
    <el-form :inline="true"  class="search-form" :mode="searchData">
      <el-form-item label="标题">
        <el-autocomplete
          class="inline-input"
          v-model="searchData.title"
          :fetch-suggestions="handleMatchArticle"
          :trigger-on-focus="false"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="作者">
        <el-input v-model="searchData.author"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select  placeholder="请选择" v-model="searchData.releaseStatus">
          <el-option label="全部" value=" "></el-option>
          <el-option v-for="(item,index) in releaseStatus" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="searchData.releaseTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>

    <div class="operator-bar">
      <el-button type="primary" icon="el-icon-edit" @click="handleCreate">新建内容</el-button>
      <el-button type="primary" icon="el-icon-download" v-if="columnId != 21" @click="handleExport">下载报表</el-button>
    </div>

    <el-table :data="listData" max-height="618" v-loading="loading == 'list'" style="width:100%;">
      <el-table-column type="index" :index="indexMethod" label="序号" width="50" prop="no"></el-table-column>
      <el-table-column prop="title" label="标题" min-width="200" show-overflow-tooltip class-name="td-align-left">
      </el-table-column>
      <el-table-column prop="author" label="作者"  show-overflow-tooltip width="150">
      </el-table-column>
      <el-table-column prop="realReadNum" label="阅读量" width="100">
      </el-table-column>
      <el-table-column prop="tagList" label="标签" show-overflow-tooltip width="150">
        <template slot-scope="{ row }">
            <span v-for="(item,index) in row.tagList" :key="item.id">{{item.tagName}}<template v-if="index < row.tagList.length-1">，</template></span>
        </template>
      </el-table-column>
      <el-table-column prop="releaseStatus" label="状态" width="80">
        <template slot-scope="{ row }">
          <el-tag :type="row.releaseStatus | statusFilter">
            {{ row.releaseStatus | formatStata }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user.userName" label="发布人"  show-overflow-tooltip width="150">
      </el-table-column>
      <el-table-column prop="releaseTime" label="发布时间" width="150">
        <template slot-scope="{ row }" v-if="row.releaseTime">
          {{ row.releaseTime | formatTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" prop="releaseStatus">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="handleUploadStatus(row)">
            {{row.releaseStatus | formatBtn}}
          </el-button>
          <el-button type="primary" @click="goDetail(row.contentId)"  size="mini">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    
   <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getArticleListCount, getArticleList,updateArticleStatus, searchArticleByName, exportArticle} from '@/api/article'
import { parseTime } from '@/utils/tool.js'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      columnId: this.$route.meta.columnId,
      // WAIT_RELEASE-待发布, RELEASED-已发布, RECALLED-已撤回
      releaseStatus: [
        {value: 'WAIT_RELEASE', name: '待发布'},
        {value: 'RELEASED', name: '已发布'},
        {value: 'RECALLED', name: '已撤回'}
      ], 
      searchData: {
        title: '',
        author: '',
        releaseStatus: '',
        releaseTime: []
      },
      loading: false,
      listData:[],
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      }
    }
  }, 
  created() {
    this.getListCount()
    this.getList()
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    getListCount() {
      let params = this.getParams()
      getArticleListCount(params).then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      let params = this.getParams()
      params.page = this.listPage.page
      params.pageSize = this.listPage.pageSize
      this.loading = 'list'
      getArticleList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    goDetail(contentId) {
      this.$router.push(this.$route.path+'/detail?contentId='+contentId)
    },
    handleCreate() {
      this.$router.push(this.$route.path+'/edit')
    },
    // 修改状态
    handleUploadStatus(row) {
      const status = row.releaseStatus == 'RELEASED' ? 'RECALLED' : 'RELEASED'
      const params = {
        contentId: row.contentId,
        releaseStatus: status
      }
      updateArticleStatus(params).then(res => {
        row.releaseStatus = status == 'RELEASED' ? 'RELEASED' : 'RECALLED'
        this.getList()
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    //标题匹配文章
    handleMatchArticle(queryString, cb) {
      let restaurants = []
      queryString = queryString.trim()
      if(queryString != ''){
        const params = {contentName:queryString}
        searchArticleByName(params).then(res => {
          restaurants = res.data.result
          restaurants.forEach(item => {
            item.value = item.title
          })
          cb(restaurants)
        })
      }else{
        cb(restaurants)
      }
    },
    //搜索
    handleSearch() {
      this.listPage.page = 1
      this.getListCount()
      this.getList()
    },
    // 下载报表
    handleExport() {
      let params = this.getParams()
      params.page = this.listPage.page
      params.pageSize = this.listPage.pageSize
      exportArticle(params).then(res => {
        var url = window.URL.createObjectURL(res)
        console.log(url)
        var a = document.createElement('a')
        a.href = url
        const name = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].meta.title
        a.download = name+'报表.xls'
        a.click()
      })
    },
    getParams() {
      const releaseStartTime = this.searchData.releaseTime && this.searchData.releaseTime[0] ? this.searchData.releaseTime[0]: ''
      const releaseEndTime = this.searchData.releaseTime && this.searchData.releaseTime[1] ? this.searchData.releaseTime[1] : ''
      const params = { 
        columnId: this.columnId,
        author: this.searchData.author,
        title: this.searchData.title,
        releaseStatus: this.searchData.releaseStatus,
        releaseStartTime: releaseStartTime,
        releaseEndTime: releaseEndTime
      }
      return params
    }
    
  },
  filters: {
    //WAIT_RELEASE-待发布, RELEASED-已发布, RECALLED-已撤回
    // el-tag类型转换
    statusFilter(status) {
      const statusMap = {
        'WAIT_RELEASE': '',
        'RELEASED': 'success',
        'RECALLED': 'info'
      }
      return statusMap[status]
    },
    // 状态显示转换
    formatStata(status) {
      const statusMap = {
        'WAIT_RELEASE': '待发布',
        'RELEASED': '已发布',
        'RECALLED': '已撤回'
      }
      return statusMap[status]
    },
    // 按钮显示转换
    formatBtn(status) {
      const statusMap = {
        'WAIT_RELEASE': '发布',
        'RELEASED': '撤回',
        'RECALLED': '发布'
      }
      return statusMap[status]
    },
    formatTime(time, format) {
      return parseTime(time, format)
    }
  }
}
</script>

<style lang="scss">
 .el-autocomplete-suggestion{
  width:auto !important;
  max-width:500px;
}
</style>